import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import headerLogoSrc from 'assets/images/header-logo.png';
import ScrollToTop from 'components/scroll-to-top';

const Footer = lazy(() => import('components/footer'));
const Header = lazy(() => import('components/header'));
const ExplainRoutes = lazy(() => import('routes/routes/explain-routes'));
const Redirect = lazy(() => import('components_legacy/pages/redirect'));
const Home = lazy(() => import('../components_legacy/pages/home/index'));
const UserRoutes = lazy(() => import('./routes/user-routes'));
const K_정시 = lazy(() => import('components_legacy/pages/jungsi/K_정시'));
const StoreRoutes = lazy(() => import('./routes/store-routes'));
const SusiRoutes = lazy(() => import('./routes/susi-routes'));

const NotFoundError = lazy(() => import('pages/errors/not-found'));

const RootRoutes = () => {
  return (
    <div className="flex flex-col overflow-hidden">
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <div className="flex min-h-screen justify-center">
          <Suspense
            fallback={
              <div className="flex min-h-screen w-full flex-col items-center justify-center gap-4">
                <img src={headerLogoSrc} className="h-auto w-10 animate-bounce" />
                <p className="">Loading...</p>
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/redirect" element={<Redirect />}></Route>
              <Route path="/store/*" element={<StoreRoutes />}></Route>
              <Route path="/user/*" element={<UserRoutes />}></Route>
              <Route path="/susi/*" element={<SusiRoutes />}></Route>
              <Route path="/explain/*" element={<ExplainRoutes />}></Route>
              <Route path="/jungsi/*" element={<K_정시 />}></Route>

              <Route path="/*" element={<NotFoundError />}></Route>
            </Routes>
          </Suspense>
        </div>
      </BrowserRouter>
      <Footer />
    </div>
  );
};

export default RootRoutes;
